import { Description, Title } from '../MainAssistancesCard/MainAssistanceCard.styles';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import { DynamicDetailList } from '../DynamicDetailList/DynamicDetailList';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { styled } from '@material-ui/styles';
import { Card as MuiCard } from '@material-ui/core';
import AssistanceDetails from './AssistanceDetails';
import FooterSwitch from './FooterSwitch';

const Card = styled(MuiCard)(({ theme }) => ({
  borderRadius: '0.5rem',
  width: '18.5rem',
  minHeight: '12.5rem',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  marginBottom: '2px',
  [theme.breakpoints.up('md')]: {
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '10.5rem',
  },
}));

const TitleDescription = styled(Box)(() => ({
  paddingLeft: '0.5rem',
}));

const Image = styled('img')(({ theme }) => ({
  width: '4rem',
  height: '4rem',
  [theme.breakpoints.up('md')]: {
    marginRight: '1rem',
  },
}));

const ItemBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '1.5rem 1.5rem 0 1.5rem',
  [theme.breakpoints.up('md')]: {
    flexGrow: (props) => {
      if (props.variant === 'big') return '3';
      return '2';
    },
  },
}));

function AssistanceItem({
  id,
  title,
  description,
  variant,
  details,
  imageSrc,
  accordionExpandedText,
  accordionClosedText,
  price,
  onChange,
  onClickExpand,
  generalConditionUrl,
  generalConditionText,
  onClickGeneralConditions,
  text,
}) {
  return (
    <Card id={id}>
      <ItemBody>
        <Image alt={title} src={imageSrc} variant={variant} aria-hidden="true" />
        <TitleDescription>
          <Title variant={variant} component="h3" id={`${id}_title`}>
            {title}
          </Title>
          <Description>{description}</Description>
        </TitleDescription>
      </ItemBody>
      {details && (
        <Hidden mdUp>
          <Box textAlign="center" px="1.5rem">
            <AssistanceDetails
              accordionExpandedText={accordionExpandedText}
              accordionClosedText={accordionClosedText}
              onClickExpand={onClickExpand}
            >
              <Box display="flex" flexDirection="column" bgcolor="white" width="100%">
                <DynamicDetailList details={details} />
                <Typography
                  variant="inherit"
                  fontFamily="ItauText-Bold"
                  component="a"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={generalConditionUrl}
                  onClick={onClickGeneralConditions}
                >
                  {generalConditionText}
                </Typography>
              </Box>
            </AssistanceDetails>
          </Box>
        </Hidden>
      )}
      <FooterSwitch price={price} text={text} onChange={onChange} ariaLabel={title} labelledById={`${id}_title`} />
    </Card>
  );
}

export default AssistanceItem;
