import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem }) => ({
  root: {
    background: props => (props.image ? `url('${props.image}') no-repeat center center` : 'transparent'),
    backgroundSize: 'cover',
    display: 'flex',

    borderRadius: `${toRem(10)}`,
    height: `${toRem(191)}`,
    width: `${toRem(262)}`,

    cursor: 'pointer',
    position: 'relative',
  },
  descriptionStripe: {
    backgroundColor: 'rgba(255 , 255, 255, 1)',
    width: '100%',
    height: `${toRem(55)}`,
    bottom: 0,
    borderRadius: '0 0 0.5rem 0.5rem',

    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'center',

    color: '#2B374A',
  },
  container: {
    display: 'grid',
    justifyItems: 'flex-start',
  },
  textContent: {
    margin: '0 1.5rem',
    color: 'inherit',
    fontFamily: 'ItauText-Bold',
    fontSize: '1.125rem',
    display: 'flex',
    alignItems: 'center',
    justifyItems: 'flex-start',
  },
  title: {
    fontSize: toRem(18),
    lineHeight: '1.43',
    margin: 0,
  },
  description: {
    fontSize: toRem(15),
  },
  icon: {
    fontSize: '2.5rem',
    color: 'inherit',
    marginRight: '1rem',
  },
  notification: {
    position: 'absolute',
    zIndex: 1,
    padding: '3px 16px',
    margin: '8px 0 0 11px',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',

    border: '1px #106EB0 solid',
    borderRadius: 8,
    backgroundColor: '#106EB0',
    color: 'white',
    fontWeight: 100,
    fontFamily: 'itauText-Light',
  },
}));
