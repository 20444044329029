import Button from 'components/Button/Button';
import { useHistory } from 'react-router-dom';
import ROUTES from 'routes/routes';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import { useStyles } from './ResidencialJumperForm.styles';
import './_residencialDropdown.scss';

const ResidencialJumperForm = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  const handleClickHireButton = () => {
    dispatchAnalyticsEvent('HOME', 'A6', ['Residencial']);
    history.push(ROUTES.LANDING_RESID);
  };

  return (
    <div className={`residencial_dropdown ${styles.root}`}>
      <Button
        type="button"
        text="contratar agora"
        onClick={handleClickHireButton}
        className={styles.btnContainer}
        classNameText={styles.btnContract}
      />
    </div>
  );
};

export default ResidencialJumperForm;
