import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem, palette }) => ({
  imageWrapper: {
    height: '100%',
    width: '100%',
    backgroundImage: props => props.image && `url('${props.image}')`,
    backgroundColor: props => props.cardBackgroundColor,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'flex-end',
  },
  cardStripe: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'start',
    backgroundColor: props => props.cardBackgroundColor || palette.white,
    width: '100%',
    height: toRem(55),
    padding: `${toRem(8)} ${toRem(16)}`,
  },
  startIcon: {
    fontSize: toRem(40),
    color: props => props.colorFont || palette.text.primary,
    marginRight: toRem(8),
  },
  endIcon: {
    color: props => props.colorFont || palette.text.primary,
    marginLeft: toRem(8),
  },
  title: {
    fontSize: toRem(18),
    textDecoration: props => props.isLink && 'underline',
    color: props => props.colorFont || palette.text.primary,
    margin: 0,
    lineHeight: 1,
  },
  subtitle: {
    fontSize: toRem(14),
    color: props => props.colorFont || palette.text.primary,
    margin: 0,
    fontWeight: '400',
  },
}));
