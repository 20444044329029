import axios from 'axios';

import config, { performance } from 'config';

const { E2E_PATH } = config;

const offerAssistance = async () => {
  const trace = performance.trace('offerAssistance');
  trace.start();
  const response = await axios.get(`${E2E_PATH}/ofertas/assistencia-saude`);
  trace.stop();
  return response.data;
};

export default offerAssistance;
