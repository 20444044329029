import { useHistory } from 'react-router-dom';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import ROUTES from 'routes/routes';
import { WelfareAssistances } from 'pages/Landings/Realiza/Welfare/WelfareMainContent';
import { useStyles } from './WelfareJumperForm.styles';

const WelfareJumperForm = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  const handleClickHireButton = () => {
    dispatchAnalyticsEvent('HOME', 'A6', ['Bem Estar']);
    history.push(ROUTES.LANDING_WELFARE);
  };

  return (
    <div className={styles.root}>
      <WelfareAssistances history={history} onClickHireButton={handleClickHireButton} />
    </div>
  );
};

export default WelfareJumperForm;
