import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '3rem',

    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  button: {
    fontSize: '1.2rem',
    margin: '0 1rem',
  },
  leftSide: {
    marginBottom: '2rem',

    [breakpoints.up('lg')]: {
      marginBottom: 0,
    },
  },
  image: {
    // width: '100%',
    width: '100%',
    height: 'auto',
    maxWidth: '15rem',

    [breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  rightSide: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: '27rem',

    [breakpoints.up('md')]: {
      marginLeft: '6rem',
    },
  },
  paragraph: {
    marginBottom: '3rem',
    fontSize: '1.2rem',
    lineHeight: '1.5rem',
  },
}));
