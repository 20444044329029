import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(({ palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '262px',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '2px solid #D5DADC',
    borderRadius: '100%',
    width: '90px',
    height: '90px',
  },
  icon: {
    color: palette.primary.main,
    fontSize: '50px',
    lineHeight: '60px',
  },
  title: {
    fontFamily: 'ItauText-XBold',
    textAlign: 'center',
    margin: '1rem 0 0.5rem 0',
    fontSize: '1.2rem',
    lineHeight: '1.43',
  },
  description: {
    textAlign: 'center',
    margin: 0,
  },
}));
