import StringField from 'shared/fields/stringField';
import BooleanField from 'shared/fields/booleanField';
import FormSchema from 'shared/form/formSchema';
import { cpfModel } from '../../../shared/form/commonModels';

const fields = {
  cpf: cpfModel({
    name: 'cpf',
    label: '',
    ariaLabel: 'cpf',
    required: true,
    requiredMessage: 'O cpf inserido é inválido',
  }),
  licensePlate: new StringField('licensePlate', '', {
    required: true,
    requiredMessage: 'Por favor insira a placa do carro',
    ariaLabel: 'Placa do veículo',
    matches: {
      pattern: /\w/g,
    },
  }),
  zeroKm: new BooleanField('zeroKm', '', {
    style: 'switch',
    yesLabel: 'sim',
    noLabel: 'não',
    ariaLabel: 'Veículo 0 quilômetro?',
  }),
};

const createSchema = zeroKm => {
  if (zeroKm) return new FormSchema({}, [fields.cpf, fields.zeroKm]);
  return new FormSchema({}, [fields.cpf, fields.licensePlate, fields.zeroKm]);
};

export { fields, createSchema };
