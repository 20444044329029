import React from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './styles';

export default function PartnerItem({ image, name, width, height }) {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <img src={image} alt={name} width={width} height={height} />
    </div>
  );
}

PartnerItem.propTypes = {
  image: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};
