import { createSelector } from 'reselect';
import { getChannel } from 'utils/channels';

const LEAD_NAMES = {
  ODONTO: 'Odonto',
  AUTO: 'Auto',
  RESID: 'Residencial',
  WELFARE: 'Assistência Bem-Estar',
  PET: 'Assistência Pet',
};

export const productsSelector = state => state.products;

export const productsLeadsSelector = createSelector(productsSelector, item => item.leads);

export const productsLeadsLoadingSelector = createSelector(productsLeadsSelector, item => item.loading);

export const productLeadsSelectorByName = name =>
  createSelector(productsLeadsSelector, item => {
    if ((!item.loading && item?.data?.length === 0) || item.error) return null;
    return item?.data?.find(i => i.name === name) || null;
  });

export const allowedProductLeadsSelectorByName = name =>
  createSelector(productLeadsSelectorByName(name), item =>
    !item['forbidden-channels']?.includes(getChannel()) ? item : null,
  );

export const allowedPetLeadSelector = allowedProductLeadsSelectorByName(LEAD_NAMES.PET);

export const allowedWelfareLeadSelector = allowedProductLeadsSelectorByName(LEAD_NAMES.WELFARE);
