import React from 'react';
import { ContactForm, Section } from 'components/Structural';
import { Loading } from 'components';
import { useSelector } from 'react-redux';
import { productsLeadsSelector } from 'store/sagas/selectors/products';

export default function DifferentialsSection({ handleSendDataDispatchAnalytics }) {
  const productsLeads = useSelector(productsLeadsSelector);

  if (productsLeads.loading) return <Loading />;
  if ((!productsLeads.loading && productsLeads?.data?.length === 0) || productsLeads.error) return [];

  return (
    <Section backgroundColor="white">
      <ContactForm
        page="newHome"
        products={productsLeads.data}
        handleSendDataDispatchAnalytics={handleSendDataDispatchAnalytics}
        isResidE2EAllowed
      />
    </Section>
  );
}
