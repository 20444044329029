import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  listContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  listItem: {
    listStyleType: 'none',
  },
}));
