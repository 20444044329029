import { styled } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';

export const DetailTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontFamily: 'ItauText-XBold',
  fontSize: '20px',
  margin: 0,
}));
export const DetailSubTitle = styled(Typography)({
  fontFamily: 'ItauText-XBold',
  fontSize: '18px',
});
export const DetailList = styled('ul')({
  paddingLeft: '1rem',
});
