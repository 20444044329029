import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ toRem, breakpoints }) => ({
  cardWrapper: {
    width: '100%',
  },
  otherExclusives: {
    fontSize: toRem(18),
    lineHeight: '1.43',
    marginLeft: toRem(12),
  },
  cartaoProtegidoCard: {
    '& .cardImage': {
      backgroundPositionX: toRem(-80),
      [breakpoints.up('md')]: {
        backgroundPositionX: 'center',
      },
    },
  },
}));
