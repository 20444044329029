import { styled } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Accordion as MuiAccordion, Card as MuiCard } from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';

export const Card = styled(MuiCard)({
  paddingTop: '1.5rem',
  borderRadius: '0.5rem',
});
export const ItemBody = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0 1.5rem',
  flexDirection: 'column',
  marginBottom: '1rem',
  maxWidth: '36rem',
  [theme.breakpoints.up('md')]: {
    flexDirection: 'row',
    padding: props => {
      if (props.variant === 'big') return '0 3.5rem';
      return '0 0.5rem';
    },
    minWidth: theme.toRem(280),
    flexGrow: props => {
      if (props.variant === 'big') return '3';
      return '2';
    },
  },
}));
export const TitleDescription = styled(Box)(() => ({}));
export const CardBody = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    flexDirection: 'row',
    padding: '0',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
export const Title = styled(Typography)({
  fontSize: props => {
    if (props.variant === 'big') return '2rem';
    return '18px';
  },
  fontFamily: 'ItauText-XBold',
  lineHeight: '1.43',
  margin: 0,
  paddingBottom: '0.25rem ',
});
export const Description = styled(Typography)({
  lineHeight: '20px',
  fontSize: '15px',
});
export const Image = styled('img')(props => {
  const { theme, variant } = props;
  return {
    width: variant === 'big' ? '5.5rem' : '4.5rem',
    height: 'min-content',
    padding: '0.5rem 0',
    [theme.breakpoints.up('md')]: {
      marginRight: '1rem',
      padding: 0,
    },
  };
});
export const FooterBody = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  backgroundColor: '#F5F5F5',
  padding: '1rem 0',
  marginTop: '2rem',
  [theme.breakpoints.up('md')]: {
    padding: '0',
    marginTop: 0,
  },
}));
export const FooterPrice = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  fontSize: '2rem',
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    padding: '0 0 0 1rem',
  },
}));
export const Accordion = styled(MuiAccordion)(({ theme }) => ({
  boxShadow: 'none',
  border: 'none',
  width: '100%',

  '&::before': {
    display: 'none',
  },
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#F5F5F5',
  },
}));
export const Link = styled(Typography)({
  textDecoration: 'none',
});
export const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '0',
    padding: '0',
  },
  content: {
    margin: 0,
    [theme?.breakpoints.up('md')]: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
}));
