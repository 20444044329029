import React, { useState } from 'react';
import { Switch as MuiSwitch } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const CardFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  backgroundColor: '#FFFFFF',
  padding: '0.25rem 1rem',
  marginTop: '0.5rem',
  [theme.breakpoints.up('md')]: {
    padding: '0 0.5rem',
    marginTop: 0,
  },
}));

const FooterPrice = styled(Typography)((props) => {
  const { theme, variant } = props;
  return {
    color: variant === 'primary' ? theme?.palette?.primary?.main : theme?.palette?.primary?.black,
    textAlign: 'left',
    fontSize: '1.25rem',
    fontWeight: 'bold',
    fontFamily: 'ItauDisplay-Regular',
  };
});

function FooterSwitch({ price, onChange, labelledById, ariaLabel = null, text = null }) {
  const [selected, setSelected] = useState(false);
  
  const switchAriaLabel = !!ariaLabel 
    ? { 'aria-label': ariaLabel }
    : { 'aria-labelledby': labelledById }
  
  return (
    <CardFooter>
      {text ? (
        <FooterPrice variant={selected ? 'primary' : ''}>{text}</FooterPrice>
      ) : (
        <FooterPrice variant={selected ? 'primary' : ''}>+{price}</FooterPrice>
      )}
      <MuiSwitch
        id={`${labelledById}_switch`}
        data-testid={`${labelledById}_switch`}
        color="primary"
        onChange={() => {
          setSelected(!selected);
          if (onChange) onChange();
        }}
        inputProps={{ ...(switchAriaLabel || {}), 'data-testid': `${labelledById}_input` }}
      />
    </CardFooter>
  );
}

export default FooterSwitch;
