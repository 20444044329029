import React, { useState } from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Card as MuiCard } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { styled } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import { DynamicDetailList } from '../DynamicDetailList/DynamicDetailList';
import Carousel from '../Carousel';
import AssistanceItem from './AssistanceItem';
import AssistanceDetails from './AssistanceDetails';

const FooterDeskBody = styled(MuiCard)(() => ({
  textAlign: 'center',
  width: 'auto',
  backgroundColor: '#F5F5F5',
  padding: '0',
  marginTop: '0.5rem',
}));

function FooterDesk({
  accordionExpandedText,
  accordionClosedText,
  accordionButtonId,
  details,
  onClickExpand,
  generalConditionUrl,
  generalConditionText,
  onClickGeneralConditions,
}) {
  return (
    <FooterDeskBody>
      <AssistanceDetails
        accordionExpandedText={accordionExpandedText}
        accordionClosedText={accordionClosedText}
        accordionButtonId={accordionButtonId}
        onClickExpand={onClickExpand}
      >
        <Box display="flex" flexDirection="column" bgcolor="white" width="100%" py={4}>
          <Box display="flex" flexDirection="row" justifyContent="space-between" bgcolor="white" px="0.5rem">
            <Box px="0.5rem">
              <DynamicDetailList details={details[0]} />
            </Box>
            <Box px="0.5rem">
              <DynamicDetailList details={details[1]} />
            </Box>
            <Box px="0.5rem">
              <DynamicDetailList details={details[2]} />
            </Box>
            <Box px="0.5rem">
              <DynamicDetailList details={details[3]} />
            </Box>
          </Box>
          <Box py="0.5rem">
            <Typography
              variant="inherit"
              fontFamily="ItauText-Bold"
              component="a"
              target="_blank"
              rel="noopener noreferrer"
              href={generalConditionUrl}
              onClick={onClickGeneralConditions}
            >
              {generalConditionText}
            </Typography>
          </Box>
        </Box>
      </AssistanceDetails>
    </FooterDeskBody>
  );
}

export default function AdditionalAssistance({
  assistanceList,
  accordionExpandedText,
  accordionClosedText,
  accordionButtonId,
  onClickExpand,
  generalConditionUrl,
  generalConditionText,
  onClickGeneralConditions,
}) {
  const combinedDetails = assistanceList?.map((item) => item.details);
  return (
    <Box>
      <Carousel
        getElements={() =>
          assistanceList.map((item) => (
            <AssistanceItem
              id={item.id}
              title={item.title}
              description={item.description}
              details={item.details}
              imageSrc={item.imageSrc}
              price={item.price}
              onChange={item?.onChange}
              accordionExpandedText={accordionExpandedText}
              accordionClosedText={accordionClosedText}
              onClickExpand={onClickExpand}
              generalConditionUrl={generalConditionUrl}
              generalConditionText={generalConditionText}
              onClickGeneralConditions={onClickGeneralConditions}
            />
          ))
        }
      />

      <Hidden smDown>
        <FooterDesk
          details={combinedDetails}
          accordionExpandedText={accordionExpandedText}
          accordionClosedText={accordionClosedText}
          accordionButtonId={accordionButtonId}
          onClickExpand={onClickExpand}
          generalConditionUrl={generalConditionUrl}
          generalConditionText={generalConditionText}
          onClickGeneralConditions={onClickGeneralConditions}
        />
      </Hidden>
    </Box>
  );
}
