import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(({ constants }) => ({
  alert: {
    height: 64,
    width: '100%',
    backgroundColor: '#007A47',
    color: '#fff',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    maxWidth: constants.MAX_CONTENT_WIDTH,
  },
  icon: {
    width: 24,
    height: 24,
    fontSize: 24,
    marginRight: 20,
  },
  text: {
    color: '#fff',
    fontSize: '16px',
    fontWeight: 400,
    textAlign: 'left',
  },
}));
