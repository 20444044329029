import { focusOnExclusivesSection } from 'pages/Home/components/ItauExclusivesSection/index';
import ROUTES from 'routes/routes';

export const PRODUCT_TITLE = 'Produto';

export const DEFAULT_OPTION = {
  label: 'Selecione',
  iconDefault: 'icon-itaufonts_seguros',
};

export const OPTIONS = [
  {
    id: 'Automóvel',
    label: 'Automóvel',
    iconDefault: 'icon-itaufonts_seguro_auto',
    iconHover: 'icon-itaufonts_full_seguro_auto',
    productPath: ROUTES.LANDING_AUTO,
  },
  {
    id: 'Residencial',
    label: 'Residencial',
    iconDefault: 'icon-itaufonts_seguro_residencia',
    iconHover: 'icon-itaufonts_full_seguro_residencia',
    productPath: ROUTES.LANDING_RESID,
  },
  {
    id: 'Odontológico',
    label: 'Odontológico',
    iconDefault: 'icon-itaufonts_sorriso',
    iconHover: 'icon-itaufonts_full_sorriso',
    productPath: ROUTES.LANDING_ODONTO,
  },
  {
    id: 'Bem-estar',
    label: 'Bem-estar',
    iconDefault: 'icon-itaufonts_seguro_vida',
    iconHover: 'icon-itaufonts_full_seguro_vida',
    productPath: ROUTES.LANDING_WELFARE,
  },
  {
    id: 'Pet',
    label: 'Pet',
    iconDefault: 'icon-itaufonts_outline_seguro_pet',
    iconHover: 'icon-itaufonts_full_seguro_pet',
    productPath: ROUTES.LANDING_PET,
  },
  {
    id: 'Exclusivos',
    label: 'Exclusivos Itaú',
    iconDefault: 'icon-itaufonts_estrela',
    iconHover: 'icon-itaufonts_estrela',
    productPath: '/',
    onClick: focusOnExclusivesSection,
  },
  {
    id: 'Carglass',
    label: 'Serviços Automotivos',
    iconDefault: 'icon-itaufonts_seguro_auto',
    iconHover: 'icon-itaufonts_full_seguro_auto',
    productPath: ROUTES.LANDING_CARGLASS,
  },
];
