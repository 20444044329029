import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/styles';
import { ButtonV2 } from 'components/Button/ButtonV2/ButtonV2';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FormikAutomaticSchema } from 'shared/form/FormikAutomaticSchema';
import FormItem from 'shared/form/FormItem';
import StringField from 'shared/fields/stringField';
import { Accordion as MuiAccordion, AccordionSummary as MuiAccordionSummary } from '@material-ui/core';
import BannerSuccess from 'components/BannerSuccess/BannerSuccess';
import { auth, database } from 'config';
import { Form } from 'formik';
import { SectionDark, SectionLight } from 'components/BackgroundSections/BackgroundSections';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import i18next from 'i18next';
import { Markdown } from 'components';

const Accordion = styled(MuiAccordion)(() => ({
  boxShadow: 'none',
  border: 'none',
  height: 'inherit',
  width: '100%',
  '&.Mui-expanded': {
    margin: 0,
  },
  '&::before': {
    display: 'none',
  },
  backgroundColor: 'inherit',
}));

const AccordionSummary = styled(MuiAccordionSummary)(({ theme }) => ({
  lineHeight: '30px',
  fontFamily: 'ItauDisplay-Bold',
  fontWeight: 'Bold',
  height: '95px',
  maxWidth: '590px',
  margin: '0 auto',

  '&.Mui-expanded': {
    margin: '0 auto',
  },

  [theme.breakpoints.up('md')]: {
    height: '60px',
  },
  color: 'primary.main',
  align: 'center',
  fontSize: '20px',
}));

export function ProductSuggestionSection() {
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();
  const [success, setSuccess] = useState(false);
  const [expanded, setExpanded] = useState(true);

  const handleSubmit = values => {
    const data = {
      suggestion: values.suggestion_name,
    };
    auth.signInAnonymously();
    auth.onAuthStateChanged(() => {
      database
        .collection('product-suggestion')
        .add(data)
        .then(() => {
          setSuccess(true);
        })
        .catch(console.error);
    });

    dispatchAnalyticsEvent('HOME', 'A22', [values.suggestion_name]);
  };

  const suggestionSectionOpenAnalytics = () => {
    setExpanded(!expanded);
    dispatchAnalyticsEvent('HOME', 'A21', [expanded ? 'Ver' : 'Esconder']);
  };

  return (
    <Accordion onChange={suggestionSectionOpenAnalytics}>
      <SectionDark px={6}>
        <AccordionSummary
          expandIcon={
            <Box color="primary.main">
              <ExpandMoreIcon />
            </Box>
          }
        >
          <Box
            lineHeight="1.75rem"
            fontWeight="Bold"
            component="h2"
            m={0}
            color="primary.main"
            align="center"
            fontSize="1.25rem"
          >
            <Box fontFamily="ItauText-Regular" component="span">
              <Markdown source={i18next.t('HOME.PRODUCT_SUGGESTION.ACCORDION_TEXT_1')} />
            </Box>
            <Box fontFamily="ItauText-Bold" component="span">
              {i18next.t('HOME.PRODUCT_SUGGESTION.ACCORDION_TEXT_2')}
            </Box>
          </Box>
        </AccordionSummary>
      </SectionDark>
      <SectionLight>
        <Box display="flex" flexDirection="column" maxWidth="34.5rem" mx="auto" px={6} pt={8}>
          <Box textAlign="center">{i18next.t('HOME.PRODUCT_SUGGESTION.SUGGESTION_DESCRIPTION')}</Box>
          <FormikAutomaticSchema onSubmit={handleSubmit} isInitialValid={false}>
            {form => (
              <Form>
                {!success && (
                  <>
                    <Box textAlign="center" py={8} width="21.75rem" mx="auto">
                      <FormItem
                        field={
                          new StringField('suggestion_name', i18next.t('HOME.PRODUCT_SUGGESTION.SUGGESTION_LABEL'), {
                            max: {
                              length: 140,
                              message: 'limite de 140 caracteres atingido',
                            },
                            required: true,
                            requiredMessage: '',
                          })
                        }
                      />
                      <Box fontSize="0.75" lineHeight="1rem" color="inputLabelColor">
                        {i18next.t('HOME.PRODUCT_SUGGESTION.CHARACTERS_LIMIT', {
                          max: 140,
                          current: form?.values?.suggestion_name?.length || 0,
                        })}
                      </Box>
                    </Box>

                    <Box width="12.5rem" mx="auto" pb={16}>
                      <ButtonV2 type="submit" disabled={!form.isValid}>
                        {i18next.t('HOME.PRODUCT_SUGGESTION.SEND_BUTTON')}
                      </ButtonV2>
                    </Box>
                  </>
                )}
              </Form>
            )}
          </FormikAutomaticSchema>
        </Box>
        {success && (
          <Box mt={8}>
            <BannerSuccess
              title={i18next.t('HOME.PRODUCT_SUGGESTION.SUCCESS_MESSAGE')}
              icon="icon-itaufonts_chat_enviado"
            />
          </Box>
        )}
      </SectionLight>
    </Accordion>
  );
}
