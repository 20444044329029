import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';

const useAutoJumper = ({ setValue, onSubmit, isValid }) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (!location?.state?.jumper) return;

    const { licensePlate, cpf, zeroKm } = location.state.jumper;

    setValue('zeroKm', zeroKm, { shouldDirty: true });
    setValue('licensePlate', licensePlate, { shouldDirty: true });
    setValue('cpf', cpf, { shouldDirty: true });
  }, [location]);

  useEffect(() => {
    if (!location?.state?.jumper || !isValid) return;
    const { licensePlate, cpf, zeroKm } = location.state.jumper;
    onSubmit({ licensePlate, cpf, zeroKm });

    const state = { ...history.location.state };
    delete state.jumper;
    history.replace({ ...history.location, state });
  }, [location, isValid]);
};

export default useAutoJumper;
