import PropTypes from 'prop-types';
import { useStyles } from './styles';

export default function DifferentialItem({ icon, title, description, titleElement, pathIcon }) {
  const styles = useStyles();
  const TitleElement = titleElement === undefined ? 'p' : titleElement;

  return (
    <div className={styles.root}>
      {pathIcon ? (
        <img data-testid="iconTestId" src={pathIcon} alt="descrição" />
      ) : (
        <div className={styles.iconWrapper}>
          <i data-testid="iconTestId" className={`${styles.icon} ${icon}`} />
        </div>
      )}
      <TitleElement className={styles.title}>{title}</TitleElement>
      <p className={styles.description}>{description}</p>
    </div>
  );
}

DifferentialItem.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};
