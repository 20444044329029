import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }) => ({
  optionInput: {
    color: palette.juridicTextColor,
    fontFamily: 'ItauText-Bold',
    margin: '1rem 0 0 0',
  },
  btnContractMarginTop: {
    marginTop: '34px',
    '& button': {
      width: '100%',
    },
  },
  btnContract: {
    maxWidth: '272px',
    width: '100%',
    padding: '10px',
    borderRadius: '4px',
  },
  btnContractText: {
    fontWeight: 'bold',
    fontSize: '1.2rem',
  },
  tooltipContainer: {
    height: '1rem',
    margin: ' 1rem 0 0 0.5rem',
  },
}));
