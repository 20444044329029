import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ constants, breakpoints, palette, toRem }) => ({
  header: {
    color: props => props.textColor || palette.white,
    fontSize: '1.25rem',
    lineHeight: '1',
    margin: 0,
    marginBottom: toRem(16),
    width: '53%',

    [breakpoints.up('md')]: {
      fontSize: '2rem',
      margin: `${toRem(40)} 0`,
    },
  },
  paragraph: {
    color: props => props.textColor || palette.white,
    fontSize: '1rem',
    lineHeight: '1',
    width: '53%',

    [breakpoints.up('md')]: {
      fontSize: '1.25rem',
    },
  },
  icon: {
    color: palette.primary.light,
    fontSize: toRem(96),
    display: 'none',

    [breakpoints.up('md')]: {
      display: 'inherit',
    },
  },
  image: {
    alignItems: 'center',
    backgroundImage: props => `url(${props.img})`,
    backgroundPosition: '65%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    height: '100%',
    width: '100%',
    '&.cashback': {
      alignItems: 'flex-end',
      paddingBottom: '60px',
      '&>div': {
        margin: '0 2.5rem',
      },
      [breakpoints.down('md')]: {
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        paddingBottom: '30px',
        '&>div': {
          margin: '0 1.5rem',
        },
      },
    },
  },
  content: {
    margin: `0 ${constants.DEFAULT_MOBILE_HORIZONTAL_MARGIN}`,
    width: '100%',

    [breakpoints.up('md')]: {
      margin: '0 4.5rem',
      width: 'auto',
    },
  },
}));

export const useStyleButton = makeStyles(({ toRem }) => ({
  root: {
    backgroundColor: props => `${props.background}`,
    color: props => `${props.color}`,
    fontWeight: 'bold',
    fontSize: toRem(19),
    whiteSpace: 'nowrap',
    padding: `${toRem(8)} ${toRem(12)}`,
    margin: toRem(2),
    marginLeft: 0,
  },
}));
