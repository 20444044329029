import React from 'react';
import clsx from 'clsx';
import { useStyles } from './InteractiveProductBanner.styles';

const InteractiveProductBanner = ({ item }) => {
  const { image, title, description, notificationText, icon, onClick } = item;
  const styles = useStyles({ image });

  return (
    <button role="link" type="button" className={styles.root} onClick={() => onClick()}>
      {notificationText && <p className={styles.notification}>{notificationText}</p>}
      <div className={styles.descriptionStripe}>
        <span className={styles.textContent}>
          <i className={clsx(styles.icon, `icon-${icon}`)} />
          <div className={styles.container}>
            <h3 className={styles.title}>{title}</h3>
            <div className={styles.description}>{description}</div>
          </div>
        </span>
      </div>
    </button>
  );
};

export default InteractiveProductBanner;
