import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    margin: '1.5rem 0 0 0',

    [breakpoints.up('xl')]: {
      margin: 0,
      display: 'flex',
      flexDirection: 'row-reverse',
    },
  },
  btnContainer: {
    width: '300px',
  },
  btnContract: {
    borderRadius: '4px',
    fontWeight: 'bold',
    padding: '10px',
    width: '272px',
    fontSize: '1.2rem',
  },
}));
