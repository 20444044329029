import React from 'react';
import i18n from 'i18next';
import MainAssistanceCard from 'components/MainAssistancesCard/MainAssistanceCard';
import { Loading } from 'components';
import { formatToBRLCurrency } from 'utils/currencyUtils';
import config from 'config';

const { URL_BUCKET_BASE } = config;

export function WelfareMainAssistance({
  assistanceList,
  monthlyPrice,
  detailsList,
  handleExpandMainAssistance,
  onClickGeneralConditions,
}) {
  if (!assistanceList) return <Loading />;

  const formattedAssistanceList = assistanceList.map((item, idx) => {
    const details = detailsList?.find(i => i.id_assistencia === item.id_assistencia)?.descricao;
    return {
      id: `chkMainAssistance_screenHome_${idx}`,
      title: item.nome?.toLowerCase(),
      description: item.resumo,
      details: details || [],
      imageSrc: `${URL_BUCKET_BASE}/bem_estar/otimizadas/ilustracoes/${item.identificador}.png`,
    };
  });

  formattedAssistanceList.unshift({
    title: i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.MAIN_ASSISTANCE.TITLE'),
    description: i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.MAIN_ASSISTANCE.DESCRIPTION'),
    imageSrc: `${URL_BUCKET_BASE}/bem_estar/otimizadas/ilustracoes/pacote_principal.png`,
    details: [],
  });

  return (
    <MainAssistanceCard
      assistanceList={formattedAssistanceList}
      accordionExpandedText={i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.ACCORDION_EXPANDED_TEXT')}
      accordionClosedText={i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.ACCORDION_CLOSED_TEXT')}
      accordionButtonId="btnExpandAssists_screenHome"
      deskFooterDescription={i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.DESK_FOOTER_DESCRIPTION')}
      footerDescription={i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.FOOTER_DESCRIPTION')}
      priceText={i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.PRICE_TEXT', {
        priceValue: formatToBRLCurrency(monthlyPrice),
      })}
      onClickExpand={handleExpandMainAssistance}
      generalConditionUrl={`${URL_BUCKET_BASE}/termos/bem_estar/cg_realiza_bem_estar.pdf`}
      generalConditionText={i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.GENERAL_CONDITIONS_TEXT')}
      onClickGeneralConditions={onClickGeneralConditions}
    />
  );
}
