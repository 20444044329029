import { Accordion, Link, useStyles } from '../MainAssistancesCard/MainAssistanceCard.styles';
import React, { useState } from 'react';
import { AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { styled } from '@material-ui/styles';

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: '0',
});

function AssistanceDetails({ accordionExpandedText, accordionClosedText, accordionButtonId, children, onClickExpand }) {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        if (onClickExpand) onClickExpand(!expanded);
        setExpanded(!expanded);
      }}
    >
      <MuiAccordionSummary classes={styles}>
        <Box py={2}>
          <Link component="a">{expanded ? accordionExpandedText : accordionClosedText}</Link>
        </Box>
      </MuiAccordionSummary>
      <AccordionDetails id={accordionButtonId}>{children}</AccordionDetails>
    </Accordion>
  );
}

export default AssistanceDetails;
