import React from 'react';
import Button from 'components/Button/Button';
import { useHistory } from 'react-router-dom';
import { useStyles } from '../ResidencialJumperForm/ResidencialJumperForm.styles';
import '../ResidencialJumperForm/_residencialDropdown.scss';

const CarglassJumperForm = () => {
  const styles = useStyles();
  const history = useHistory();

  return (
    <div className={`residencial_dropdown ${styles.root}`}>
      <Button
        type="button"
        text="contratar agora"
        onClick={() => history.push('/carglass')}
        className={styles.btnContainer}
        classNameText={styles.btnContract}
      />
    </div>
  );
};

export default CarglassJumperForm;
