import React from 'react';
import { useStyles } from './styles';

const DesktopPartnerContainer = ({ getElements }) => {
  const styles = useStyles();

  return (
    <ul className={styles.listContainer}>
      {getElements().map((partner, index) => (
        <li className={styles.listItem} key={`partner-${index}`}>
          {partner}
        </li>
      ))}
    </ul>
  );
};

export default DesktopPartnerContainer;
