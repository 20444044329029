import React from 'react';
import { Box, styled } from '@material-ui/core';

const Divider = styled(Box)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.defaultTextColor}`,
  width: '30%',
}));
const SubheaderDividerBody = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

export function SubheaderWithDivider({ children }) {
  return (
    <SubheaderDividerBody>
      <Divider />
      {children}
      <Divider />
    </SubheaderDividerBody>
  );
}
