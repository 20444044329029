import axios from 'axios';

import config, { performance } from 'config';

const { E2E_PATH } = config;

const assistanceDetail = async (idOffer, assitanceIdList) => {
  const trace = performance.trace('assistanceDetail');
  trace.start();
  const response = await axios.get(
    `${E2E_PATH}/ofertas/${idOffer}/assistencias`,
    {
      params: {
        ids: assitanceIdList?.join(','),
      },
    },
  );
  trace.stop();
  return response.data;
};

export default assistanceDetail;
