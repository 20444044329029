import { useState, useRef } from 'react';
import config from 'config';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import PetJumperForm from 'components/Jumper/forms/PetJumperForm';
import CarglassJumperForm from 'components/Jumper/forms/CarglassJurmperForm/CarglassJumperForm';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import { useStyles } from './styles/styles';
import DropdownSelector from '../DropdownSelector';
import AutoJumperForm from './forms/AutoJumperForm';
import OdontoJumperForm from './forms/OdontoJumperForm';
import ResidencialJumperForm from './forms/ResidencialJumperForm';
import { DEFAULT_OPTION, OPTIONS, PRODUCT_TITLE } from './constants';
import './styles/_jumper.scss';
import WelfareJumperForm from './forms/WelfareJumperForm';

const Jumper = ({
  isPetE2EAllowed,
  isWelfareE2EAllowed,
  isCarglassAllowed,
  initialOption,
  redirectOnChange,
  initHiring,
  onChangeSelection,
  submitJumperButtonRef,
  buttonIsLoading,
  disabled,
  remoteConfig,
}) => {
  const initialSelectedOption = initialOption?.label || DEFAULT_OPTION.label;
  const oldSelectedOption = useRef(initialSelectedOption);
  const [selectedOption, setSelectedOption] = useState(initialSelectedOption);
  const history = useHistory();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();
  const { ITAU_EXCLUSIVES_SECTION } = config;

  const LARGE_CONTENT_OPTIONS = ['Bem-estar'];
  const isLargeContent = LARGE_CONTENT_OPTIONS.find(option => option === selectedOption);

  const styles = useStyles({ isLargeContent });

  if (!isWelfareE2EAllowed) OPTIONS[3].disabled = true;
  if (!isPetE2EAllowed) OPTIONS[4].disabled = true;
  if (!ITAU_EXCLUSIVES_SECTION.enabled) OPTIONS[5].disabled = true;
  if (!isCarglassAllowed) OPTIONS[6].disabled = true;

  const SwitchOption = ({ product }) => {
    switch (product) {
      case OPTIONS[0].label:
        return (
          <section className={`optionContainer ${styles.optionForm}`}>
            <AutoJumperForm
              initHiring={initHiring}
              submitJumperButtonRef={submitJumperButtonRef}
              buttonIsLoading={buttonIsLoading}
              remoteConfig={remoteConfig}
            />
          </section>
        );
      case OPTIONS[1].label:
        return (
          <section className={styles.optionContainer}>
            <ResidencialJumperForm />
          </section>
        );
      case OPTIONS[2].label:
        return (
          <section className={styles.optionContainer}>
            <OdontoJumperForm />
          </section>
        );
      case OPTIONS[3].label:
        return (
          <section className={styles.optionContainer}>
            <WelfareJumperForm />
          </section>
        );
      case OPTIONS[4].label:
        return (
          <section className={styles.optionContainer}>
            <PetJumperForm />
          </section>
        );
      case OPTIONS[6].label:
        return (
          <section className={styles.optionContainer}>
            <CarglassJumperForm />
          </section>
        );
      default:
        return <div />;
    }
  };

  const handleChange = value => {
    const selectedOptionObject = OPTIONS.find(option => option.label === value);
    if (typeof onChangeSelection === 'function') onChangeSelection({ selectedValue: value, oldValue: selectedOption });

    dispatchAnalyticsEvent('HOME', 'A5', [oldSelectedOption.current, value]);
    oldSelectedOption.current = value;
    setSelectedOption(value);

    if (redirectOnChange) return history.push(selectedOptionObject.productPath);
    if (selectedOptionObject?.onClick) return selectedOptionObject.onClick();
  };

  return (
    <section className={styles.jumperContainer} data-testid="jumper">
      <div className={styles.jumper}>
        <p className={styles.titleInput} id="dropdownLabel" data-testid="dropdownLabel">
          {PRODUCT_TITLE}
        </p>
        <DropdownSelector
          id="home-jumper"
          options={OPTIONS}
          defaultOption={DEFAULT_OPTION}
          initialOption={initialOption}
          ariaLabel={
            selectedOption === DEFAULT_OPTION ? 'Selecione o produto' : `Produto selecionado: ${selectedOption}`
          }
          onSelect={handleChange}
          disabled={disabled}
        />
      </div>
      <SwitchOption product={selectedOption} />
    </section>
  );
};

export default Jumper;

Jumper.propTypes = {
  isPetE2EAllowed: PropTypes.bool,
  isWelfareE2EAllowed: PropTypes.bool,
  initialOption: PropTypes.shape({
    label: PropTypes.string.isRequired,
    iconDefault: PropTypes.string,
    iconHover: PropTypes.string,
    productPath: PropTypes.string,
  }),
  redirectOnChange: PropTypes.bool,
  buttonIsLoading: PropTypes.func,
};

Jumper.defaultOption = {
  redirectOnChange: false,
};
