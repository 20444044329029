import React, { useState } from 'react';
import { AccordionDetails as MuiAccordionDetails, AccordionSummary as MuiAccordionSummary } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import { styled } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {
  Accordion,
  Card,
  CardBody,
  Description,
  FooterBody,
  FooterPrice,
  Image,
  ItemBody,
  Link,
  Title,
  TitleDescription,
  useStyles,
} from './MainAssistanceCard.styles';
import { DynamicDetailList } from '../DynamicDetailList/DynamicDetailList';

function AssistanceItem({
  id,
  title,
  description,
  variant,
  details,
  imageSrc,
  accordionExpandedText,
  accordionClosedText,
  onClickExpand,
  generalConditionUrl,
  generalConditionText,
  onClickGeneralConditions,
}) {
  return (
    <ItemBody id={id} variant={variant}>
      <Image alt={title} src={imageSrc} variant={variant} />
      <TitleDescription>
        <Title variant={variant} component="h3">
          {title}
        </Title>
        <Description>{description}</Description>
      </TitleDescription>
      {details && (
        <Hidden mdUp>
          <AssistanceDetails
            accordionExpandedText={accordionExpandedText}
            accordionClosedText={accordionClosedText}
            details={details}
            onClickExpand={onClickExpand}
            generalConditionUrl={generalConditionUrl}
            generalConditionText={generalConditionText}
            onClickGeneralConditions={onClickGeneralConditions}
          />
        </Hidden>
      )}
    </ItemBody>
  );
}

const AccordionDetails = styled(MuiAccordionDetails)({
  padding: 0,
});

function AssistanceDetails({
  accordionExpandedText,
  accordionClosedText,
  details,
  onClickExpand,
  generalConditionUrl,
  generalConditionText,
  onClickGeneralConditions,
}) {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={() => {
        if (onClickExpand) onClickExpand(!expanded);
        setExpanded(!expanded);
      }}
    >
      <MuiAccordionSummary classes={styles}>
        <Link component="a" data-testid="AccordionLink">
          {expanded ? accordionExpandedText : accordionClosedText}
        </Link>
      </MuiAccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" bgcolor="white" width="100%">
          <DynamicDetailList details={details} />
          <Typography
            variant="inherit"
            fontFamily="ItauText-Bold"
            component="a"
            target="_blank"
            rel="noopener noreferrer"
            href={generalConditionUrl}
            onClick={onClickGeneralConditions}
          >
            {generalConditionText}
          </Typography>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
}

function Footer({ priceText, descriptionText }) {
  return (
    <FooterBody>
      <Description>{descriptionText}</Description>
      <FooterPrice>{priceText}</FooterPrice>
    </FooterBody>
  );
}

function FooterDesk({
  accordionExpandedText,
  accordionClosedText,
  accordionButtonId,
  descriptionText,
  details,
  priceText,
  onClickExpand,
  generalConditionUrl,
  generalConditionText,
  onClickGeneralConditions,
}) {
  const styles = useStyles();
  const [expanded, setExpanded] = useState(false);

  return (
    <FooterBody>
      <Accordion
        expanded={expanded}
        onChange={() => {
          setExpanded(!expanded);
          if (onClickExpand) onClickExpand(!expanded);
        }}
      >
        <MuiAccordionSummary classes={styles}>
          <Box py={2}>
            <Link component="a">{expanded ? accordionExpandedText : accordionClosedText}</Link>
            <div aria-hidden style={{ position: 'absolute', right: '1rem', display: 'inline-flex', marginTop: '-8px' }}>
              <Description style={{ alignSelf: 'center' }}>{descriptionText}</Description>
              <FooterPrice>{priceText}</FooterPrice>
            </div>
          </Box>
        </MuiAccordionSummary>
        <AccordionDetails id={accordionButtonId}>
          <Box display="flex" flexDirection="column" bgcolor="white" width="100%" py={4}>
            <Box
              display="flex"
              flexDirection="row"
              bgcolor="white"
              width="100%"
              height="100%"
              justifyContent="space-evenly"
            >
              <DynamicDetailList details={details[0]} />
              <DynamicDetailList details={details[1]} />
            </Box>
            <Box py="0.5rem">
              <Typography
                variant="inherit"
                fontFamily="ItauText-Bold"
                component="a"
                target="_blank"
                rel="noopener noreferrer"
                href={generalConditionUrl}
                onClick={onClickGeneralConditions}
              >
                {generalConditionText}
              </Typography>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </FooterBody>
  );
}

function MainAssistanceCard({
  assistanceList,
  priceText,
  accordionExpandedText,
  accordionClosedText,
  footerDescription,
  deskFooterDescription,
  accordionButtonId,
  onClickExpand,
  generalConditionUrl,
  generalConditionText,
  onClickGeneralConditions,
}) {
  return (
    <Card>
      <CardBody>
        <AssistanceItem
          title={assistanceList[0].title}
          description={assistanceList[0].description}
          imageSrc={assistanceList[0].imageSrc}
          variant="big"
        />
        <AssistanceItem
          id={assistanceList[1].id}
          title={assistanceList[1].title}
          description={assistanceList[1].description}
          details={assistanceList[1].details}
          imageSrc={assistanceList[1].imageSrc}
          accordionExpandedText={accordionExpandedText}
          accordionClosedText={accordionClosedText}
          onClickExpand={onClickExpand}
          generalConditionUrl={generalConditionUrl}
          generalConditionText={generalConditionText}
          onClickGeneralConditions={onClickGeneralConditions}
        />
        <AssistanceItem
          id={assistanceList[2].id}
          title={assistanceList[2].title}
          description={assistanceList[2].description}
          details={assistanceList[2].details}
          imageSrc={assistanceList[2].imageSrc}
          accordionExpandedText={accordionExpandedText}
          accordionClosedText={accordionClosedText}
          onClickExpand={onClickExpand}
          generalConditionUrl={generalConditionUrl}
          generalConditionText={generalConditionText}
          onClickGeneralConditions={onClickGeneralConditions}
        />
      </CardBody>
      <Hidden smDown>
        <div style={{ opacity: '0', height: '0' }}>
          {deskFooterDescription} {priceText}
        </div>
        <FooterDesk
          details={[assistanceList[1].details, assistanceList[2].details]}
          accordionExpandedText={accordionExpandedText}
          accordionClosedText={accordionClosedText}
          descriptionText={deskFooterDescription}
          priceText={priceText}
          accordionButtonId={accordionButtonId}
          onClickExpand={onClickExpand}
          generalConditionUrl={generalConditionUrl}
          generalConditionText={generalConditionText}
          onClickGeneralConditions={onClickGeneralConditions}
        />
      </Hidden>
      <Hidden mdUp>
        <Footer priceText={priceText} descriptionText={footerDescription} />
      </Hidden>
    </Card>
  );
}

export default MainAssistanceCard;
