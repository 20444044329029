import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid } from '@material-ui/core';
import parser from 'html-react-parser';
import Markdown from 'components/Markdown';
import { useStyles, useStyleButton } from './styles';

const StyledButton = props => {
  const { background, textColor, classe } = props;
  const buttonStyle = useStyleButton({
    background,
    color: textColor,
  });
  return <Button className={`${buttonStyle.root} ${classe}`} {...props} />;
};

const CrossfadeComponent = ({
  icon,
  title,
  subtitle,
  customContent,
  buttonsList,
  image,
  className,
  typeCard,
  textColor,
}) => {
  const styles = useStyles({ img: image, typeBackground: typeCard, textColor });
  const history = useHistory();

  function openLink(link) {
    const flag = link.search(/#/i);
    const http = link.search(/http/i);
    if (flag > -1) document.getElementById(link.substring(1, link.length)).scrollIntoView();
    else if (http > -1) window.open(link, '_blank');
    else history.push(link);
  }

  return (
    <div className={`${styles.image} ${className}`}>
      <div className={styles.content}>
        {icon && <i aria-hidden className={`icon-${icon} ${styles.icon}`} data-testid={`${title}-icon-testid`} />}
        {title && (
          <h2 className={`${styles.header} crossfade-title`}>
            <Markdown source={parser(title)} />
          </h2>
        )}
        {subtitle && (
          <p className={`${styles.paragraph} crossfade-subtitle`}>
            <Markdown source={parser(subtitle)} />
          </p>
        )}
        {customContent && <div>{customContent()}</div>}

        {buttonsList && (
          <Grid container className="crossfade-button-wrapper" xs={12} md={buttonsList.length > 1 ? 6 : 12}>
            {buttonsList.map((buttons, index) => {
              const button = buttons.button_item;

              return (
                <Grid container item xs key={index}>
                  <StyledButton
                    onClick={() => openLink(button.button.href)}
                    background={button.button_color_background}
                    textColor={button.button_text_color}
                    variant="contained"
                    classe={`${styles.button} ${button.className}`}
                    disableRipple
                    disableFocusRipple
                  >
                    {parser(button.button.title)}
                  </StyledButton>
                </Grid>
              );
            })}
          </Grid>
        )}
      </div>
    </div>
  );
};

export default CrossfadeComponent;
