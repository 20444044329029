import { batch, useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import offerAssistance from 'api/E2E/Realiza/offerAssistance';
import assistanceDetail from 'api/E2E/Offers/Assistance/assistanceDetail';
import { Creators } from 'store/ducks/welfare';
import { resetWizardStep } from 'store/ducks/wizardStep';
import { Section } from 'components/Structural';
import Box from '@material-ui/core/Box';
import { WelfareMainAssistance } from 'pages/Landings/Realiza/Welfare/WelfareMainAssistanceCard';
import { WelfareAdditionalAssistanceSubheader } from 'pages/Landings/Realiza/Welfare/WelfareAdditionalAssistanceSubheader';
import { WelfareAdditionalAssistance } from 'pages/Landings/Realiza/Welfare/WelfareAdditionalAssistance';
import { WelfareFullPrice } from 'pages/Landings/Realiza/Welfare/WelfareFullPrice';
import i18n from 'i18next';
import { formatToBRLCurrency } from 'utils/currencyUtils';
import { useAnalyticsV2ProviderConsumer } from '../../../../providers/AnalyticsV2Provider';

export function WelfareAssistances({ onClickHireButton }) {
  const dispatch = useDispatch();
  const [detailsPayload, setDetailsPayload] = useState([]);
  const [assistanceList, setAssistanceList] = useState(null);
  const [identifier, setIdentifier] = useState([]);
  const [selectedAssistance, setSelectedAssistance] = useState({});
  const [offerId, setOfferId] = useState();
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  useEffect(() => {
    (async () => {
      const offerPayload = await offerAssistance();
      const payloadAssistanceList = offerPayload?.produtos[0];
      setAssistanceList(payloadAssistanceList);
      setOfferId(offerPayload.id_oferta);
      setDetailsPayload(
        await assistanceDetail(
          offerPayload.id_oferta,
          payloadAssistanceList?.assistencias?.map((item) => item.id_assistencia)
        )
      );
    })();
  }, []);

  const handleSelectCoverage = (item, identifier) => {
    setIdentifier((prevState) => {
      if (prevState.includes(identifier)) {
        return prevState.filter((assistance) => assistance !== identifier);
      } else {
        return [...prevState, identifier];
      }
    });
    setSelectedAssistance({ ...selectedAssistance, [item]: !selectedAssistance[item] });
  };

  const handleSendAnalyticsHireNow = () => {
    const assistances = identifier.length > 0 ? identifier.toString() : 'SemAssistencia';
    dispatchAnalyticsEvent('WELFARE_HOME', 'A4', [assistances]);
  };

  const handleSubmit = () => {
    handleSendAnalyticsHireNow();
    const assistencias = assistanceList?.assistencias.reduce((acc, curr) => {
      if (curr.assistencia_obrigatoria || selectedAssistance[curr.id_assistencia]) {
        acc.push(curr);
      }
      return acc;
    }, []);
    batch(() => {
      dispatch(
        Creators.triggerOfferSagaAction({
          assistencias,
          id_oferta: offerId,
          id_produto: assistanceList?.id_produto,
          valor_mensal_minimo: assistanceList.valor_mensal_minimo,
        })
      );
      dispatch(resetWizardStep());
    });

    if (typeof onClickHireButton === 'function') onClickHireButton();
  };

  const handleExpandMainAssistance = (expanded) => {
    const labels = expanded ? 'ExpandirDetalhes' : 'RecolherDetalhes';
    dispatchAnalyticsEvent('WELFARE_HOME', 'A1', [labels]);
  };

  const handleExpandAdditionalAssistance = (expanded) => {
    const labels = expanded ? 'ExpandirDetalhes' : 'RecolherDetalhes';
    dispatchAnalyticsEvent('WELFARE_HOME', 'A3', [labels]);
  };

  const handleClickGeneralConditions = (isMainAssistance) => () => {
    const labels = isMainAssistance ? 'PacotePrincipal' : 'MaisAssistencias';
    dispatchAnalyticsEvent('WELFARE_HOME', 'A2', [labels]);
  };

  const totalAssistancePrice = () => {
    if (!assistanceList) return 0;
    const mandatoryAssistancePrice = assistanceList?.valor_mensal_minimo;

    return assistanceList.assistencias.reduce((acc, cur) => {
      if (cur.assistencia_obrigatoria) return acc;
      if (!selectedAssistance[cur.id_assistencia]) return acc;
      return acc + cur.premio;
    }, mandatoryAssistancePrice);
  };
  return (
    <Box maxWidth="1300px">
      <WelfareMainAssistance
        assistanceList={assistanceList?.assistencias}
        monthlyPrice={assistanceList?.valor_mensal_minimo}
        detailsList={detailsPayload}
        handleExpandMainAssistance={handleExpandMainAssistance}
        onClickGeneralConditions={handleClickGeneralConditions(true)}
      />
      <br />
      <WelfareAdditionalAssistanceSubheader />
      <br />
      <WelfareAdditionalAssistance
        handleSelectCoverage={handleSelectCoverage}
        assistanceList={assistanceList?.assistencias}
        detailsList={detailsPayload}
        handleExpandAdditionalAssistance={handleExpandAdditionalAssistance}
        onClickGeneralConditions={handleClickGeneralConditions(false)}
      />
      <br />
      <WelfareFullPrice
        totalPrice={i18n.t('WELFARE.LANDING.HOME.TOTAL_PRICE', {
          priceValue: formatToBRLCurrency(totalAssistancePrice()),
        })}
        totalText={i18n.t('WELFARE.LANDING.HOME.TOTAL_PRICE_TEXT')}
        buttonText={i18n.t('WELFARE.LANDING.HOME.SUBMIT_BUTTON_TEXT')}
        onSubmit={handleSubmit}
        buttonId="btnStartHiring_screenHomeBemEstar"
      />
    </Box>
  );
}

export function WelfareMainContent({ onClickHireButton }) {
  return (
    <Section backgroundColor="#EFE9E5">
      <WelfareAssistances onClickHireButton={onClickHireButton} />
    </Section>
  );
}
