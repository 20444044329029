import React from 'react';
import Box from '@material-ui/core/Box';
import { DetailList, DetailSubTitle, DetailTitle } from './DynamicDetailList.styles';

export function DynamicDetailList({ details }) {
  return (
    <Box flexDirection="row" textAlign="start" maxWidth="29.5rem">
      {details?.map((item, idx) => {
        switch (item.tipo) {
          case 'topico':
            return (
              <DetailList key={idx}>
                {item.conteudo.split('\n').map((i, index) => (
                  <li key={index}>{i}</li>
                ))}
              </DetailList>
            );
          case 'subtitulo':
            return <DetailSubTitle key={idx}>{item.conteudo}</DetailSubTitle>;
          case 'paragrafo':
            return <p key={idx}>{item.conteudo}</p>;
          case 'titulo':
            return (
              <DetailTitle key={idx} component="h4">
                {item.conteudo}
              </DetailTitle>
            );
          default:
            break;
        }
        return <></>;
      })}
    </Box>
  );
}
