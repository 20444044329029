import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(({ breakpoints }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '150px',
    [breakpoints.up('lg')]: {
      width: 'auto',
    },
  },
}));
