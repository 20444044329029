import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash';
import useWindowWidth from 'hooks/useWindowWidth';
import { useStyles } from './styles';

/**
 * Carousel component
 * @param {function} getElements must be a function that returns a array of elements
 * @param {boolean} showDots show carousel index dots
 * @param {number} parentMargin must be the margin value between this component and the screen left border, in pixels
 * */
export default function Carousel({ getElements, showDots, parentMargin }) {
  const windowWidth = useWindowWidth();
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);
  const [totalPagesNumber, setTotalPagesNumber] = useState(0);
  const carouselRef = useRef();
  const oldHandleScroll = useRef();
  const carouselItemsRef = useRef({});

  const CAROUSEL_ITEMS_SPACING = parentMargin;
  const ITEM_WIDTH = carouselItemsRef.current[0]?.offsetWidth;
  const ITEM_HEIGHT = carouselItemsRef.current[0]?.offsetHeight;

  const styles = useStyles({ CAROUSEL_ITEMS_SPACING, ITEM_WIDTH, ITEM_HEIGHT, totalPagesNumber, parentMargin });

  /** Returns how many items each page have */
  const getItemsPerPage = () =>
    Math.floor(
      (carouselRef.current.offsetWidth + CAROUSEL_ITEMS_SPACING) /
        (carouselItemsRef.current[0].offsetWidth + CAROUSEL_ITEMS_SPACING),
    ) || 1;

  /** Returns the page width, considering items per page number */
  const getPageWidth = () => (carouselItemsRef.current[0].offsetWidth + CAROUSEL_ITEMS_SPACING) * getItemsPerPage();

  const handleScroll = useCallback(
    debounce(() => {
      const newPageNumber =
        carouselRef.current.scrollLeft === carouselRef.current.scrollWidth - carouselRef.current.offsetWidth
          ? totalPagesNumber - 1
          : Math.round(carouselRef.current.scrollLeft / getPageWidth());

      setSelectedPageNumber(newPageNumber);
    }, 100),
    [totalPagesNumber],
  );

  /** Remove old scroll listener from carousel and add the new updated one */
  const updateScrollListener = () => {
    carouselRef.current.removeEventListener('scroll', oldHandleScroll.current);
    carouselRef.current.addEventListener('scroll', handleScroll);
  };

  useEffect(() => {
    updateScrollListener();
  }, [totalPagesNumber]);

  useEffect(() => {
    if (!carouselRef.current || !windowWidth) return;

    oldHandleScroll.current = handleScroll;

    setSelectedPageNumber(0);
    carouselRef.current.scrollLeft = 0;
    setTotalPagesNumber(Math.ceil(getElements().length / getItemsPerPage()));
  }, [carouselRef, windowWidth]);

  return (
    <div className={styles.root}>
      <ul
        className={styles.carousel}
        ref={carouselRef}
        data-testid="carousel"
      >
        {getElements().map((item, i) => (
          <li
            key={`carousel-item-${i}`}
            className={styles.carouselItem}
            ref={el => (carouselItemsRef.current[i] = el)}
            data-testid={`carousel-item-${i}`}
          >
            {item}
          </li>
        ))}
      </ul>
      {showDots && (
        <div className={styles.dotsGroup} data-testid="carousel-dots">
          {totalPagesNumber > 1 &&
            [...Array(totalPagesNumber).keys()].map(index => (
              <button
                key={`carousel-dot-${index}`}
                type="button"
                data-testid={`carousel-dot-${index}`}
                aria-label={`Item ${index}`}
                aria-hidden
                className={`${styles.dotItem} ${selectedPageNumber === index ? styles.dotItemSelected : ''}`}
                onClick={() => {
                  carouselItemsRef.current[index * getItemsPerPage()].scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'start',
                  });
                }}
              />
            ))}
        </div>
      )}
    </div>
  );
}

Carousel.propTypes = {
  getElements: PropTypes.func.isRequired,
  showDots: PropTypes.bool,
  parentMargin: PropTypes.number,
};

Carousel.defaultProps = {
  showDots: true,
  parentMargin: 24,
};
