import React, { useState, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import Button from 'components/Button/Button';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import HookFormUnit from 'shared/hookForm/HookFormUnit';
import HookFormItem from 'shared/hookForm/HookFormItem';
import { useAnalyticsV2ProviderConsumer } from 'providers/AnalyticsV2Provider';
import useAutoJumper from 'components/Jumper/hooks/useAutoJumper';
import { getPureChannel } from 'utils/channels';
import ROUTES from 'routes/routes';
import { fields, createSchema } from '../../Model/AutoForm.model';
import { useStyles } from './AutoJumperForm.styles.js';
import DarkTooltip from '../../../DarkTooltip/index';

const AutoFormContent = ({ onSubmit, schema, setSchema, submitJumperButtonRef, buttonIsLoading, recreateSchema }) => {
  const { formState, setValue, getValues, trigger, clearErrors } = useFormContext();
  const styles = useStyles();
  const isZeroKm = getValues('zeroKm');
  const schemaRecreated = useRef(false);

  useEffect(() => {
    setSchema(createSchema(isZeroKm));
    clearErrors();
    if (isZeroKm) setValue('licensePlate', '');
  }, [isZeroKm]);

  useEffect(() => {
    if (formState.submitCount > 0 || schemaRecreated.current) {
      trigger();
    }
  }, [schema]);

  const customSetValue = (field, value, options) => {
    setValue(field, value, options);
    if (field === 'zeroKm') {
      recreateSchema(value, trigger);
      schemaRecreated.current = true;
    }
  };

  useAutoJumper({ setValue: customSetValue, onSubmit, isValid: formState.isValid });

  return (
    <Grid container item xs={12} spacing={6}>
      <Grid item xs={12} md={6} xl={3}>
        <p className={styles.optionInput} aria-hidden>
          Placa do veículo
        </p>
        <HookFormItem disabled={isZeroKm} field={fields.licensePlate} />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <p className={styles.optionInput} aria-hidden>
          Seu CPF
        </p>
        <HookFormItem field={fields.cpf} />
      </Grid>
      <Grid item xs={12} md={6} xl={3}>
        <Grid container xl={12}>
          <p className={styles.optionInput}>Veículo 0 km?</p>
          <div className={styles.tooltipContainer}>
            <DarkTooltip
              title="o que é um veículo 0km?"
              placement="top"
              content="Veículo que não tenha as suas características originais alteradas, esteja em nome do primeiro proprietário e que não tenha saído da revendedora/concessionária autorizada."
              darkIcon
              darkBackground
            />
          </div>
        </Grid>
        <HookFormItem field={fields.zeroKm} />
      </Grid>
      <Grid item xs={12} md={6} xl={3} className={styles.btnContractMarginTop}>
        <Button
          customId="btnjumper_auto"
          type="submit"
          text="contratar agora"
          className={styles.btnContract}
          classNameText={styles.btnContractText}
          disabled={buttonIsLoading || (!formState.isValid && !!formState.touched && formState.submitCount > 0)}
          loading={buttonIsLoading}
          ref={submitJumperButtonRef}
        />
      </Grid>
    </Grid>
  );
};

function AutoJumperForm({ initHiring, submitJumperButtonRef, buttonIsLoading, remoteConfig }) {
  const [schema, setSchema] = useState(createSchema(false));
  const dispatchAnalyticsEvent = useAnalyticsV2ProviderConsumer();

  const initializedRemoteConfig = remoteConfig || {};

  const { ENABLE_AUTOV2_STYLE, AUTO_V2_ENABLED_CHANNELS } = initializedRemoteConfig;
  const recreateSchema = zeroKm => {
    setSchema(createSchema(zeroKm));
  };

  const history = useHistory();

  const { pathname } = useLocation();

  const mountHiringRouteRedirect = (cpf, licensePlate, isZeroKm) => {
    const { search } = window.location;
    let complementarySearch = '';
    if (search.length === 0) {
      complementarySearch += '?';
    } else {
      complementarySearch += '&';
    }
    complementarySearch += `cpf=${cpf}`;
    if (licensePlate) {
      complementarySearch += `&licensePlate=${licensePlate}`;
    } else {
      complementarySearch += `&isZeroKm=${isZeroKm}`;
    }

    const autoV1TestAbParams = {
      licensePlate,
      cpf,
      isZeroKm
    }

    sessionStorage.setItem("auto:v1:testab:cpf", cpf);
    sessionStorage.setItem("auto:v1:testab:licensePlate", licensePlate);
    sessionStorage.setItem("auto:v1:testab:isZeroKm", isZeroKm);
  
    return `${ROUTES.AUTO_V2_HIRING}${search}`;
  }

  const handleSubmitTestABByV1Page = async request => {
    const localRootStorage = sessionStorage.getItem('persist:root');
    const { cpf, licensePlate, zeroKm } = request;
    if (localRootStorage) {
      const parsedLocalRootStorage = JSON.parse(localRootStorage);
      if (parsedLocalRootStorage) {
        parsedLocalRootStorage.actor = JSON.stringify({
          driver: {
            cpf,
          },
        });
        parsedLocalRootStorage.step = '1';
        parsedLocalRootStorage.itemRisk = JSON.stringify({
          automobile: {
            lastInsurance: {},
            plate: licensePlate,
            zeroKm,
          },
        });
        sessionStorage.setItem('persist:root', JSON.stringify(parsedLocalRootStorage));
      }
    }
    window.location.href = mountHiringRouteRedirect(cpf, licensePlate, zeroKm);
  };
  const enabledAutoV2ByChannelRule = AUTO_V2_ENABLED_CHANNELS?.includes(getPureChannel());

  const onSubmit = async values => {
    if (
      pathname === ROUTES.LANDING_AUTO ||
      pathname === ROUTES.AUTO_V2_HOME ||
      pathname === `${ROUTES.AUTO_V2_HOME}/`
    ) {
      if (!ENABLE_AUTOV2_STYLE && enabledAutoV2ByChannelRule) {
        handleSubmitTestABByV1Page(values);
      } else {
        dispatchAnalyticsEvent('AUTO', 'A22', ['Automovel']);
        const { ...data } = schema.cast(values);
        initHiring(data);
      }
    } else {
      dispatchAnalyticsEvent('HOME', 'A6', ['Automóvel']);
      history.push({ pathname: ROUTES.LANDING_AUTO, state: { jumper: values } });
    }
  };

  return (
    <HookFormUnit
      mode="onSubmit"
      reValidateMode="onChange"
      schema={schema.schema}
      defaultValues={{ zeroKm: false }}
      onSubmit={onSubmit}
    >
      <AutoFormContent
        onSubmit={onSubmit}
        schema={schema}
        setSchema={setSchema}
        submitJumperButtonRef={submitJumperButtonRef}
        buttonIsLoading={buttonIsLoading}
        recreateSchema={recreateSchema}
      />
    </HookFormUnit>
  );
}

export default AutoJumperForm;
