import React from 'react';
import { styled } from '@material-ui/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from 'components/Button/Button';

const TotalPrice = styled(Typography)(({ theme }) => ({
  color: theme?.palette?.primary?.main,
  fontWeight: 'bold',
  fontSize: '1.5rem',
  display: 'inline-block',
  marginBottom: '1rem',
}));
const TotalText = styled(Typography)({
  fontFamily: 'ItauText-Bold',
  fontSize: '1.5rem',
  display: 'inline-block',
});
const FullPriceBody = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

export function WelfareFullPrice({ onSubmit, totalText, totalPrice, buttonText, buttonId }) {
  return (
    <FullPriceBody>
      <div aria-live="polite" aria-atomic>
        <TotalText>{totalText}:&nbsp; </TotalText>
        <TotalPrice id="lblTotalValue"> {totalPrice}</TotalPrice>
      </div>
      <Button
        customId={buttonId}
        text={buttonText}
        onClick={onSubmit}
        className="button--orange button--medium button--small"
      />
    </FullPriceBody>
  );
}
