import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  jumperContainer: {
    [theme.breakpoints.up('xl')]: {
      margin: 0,
      display: 'flex',
      flexDirection: props => (props.isLargeContent ? 'column' : 'row'),
      justifyContent: 'space-between',
    },
  },
  jumper: {
    fontFamily: 'ItauText-Regular',
    width: '100%',
    marginBottom: '1rem',
    position: 'relative',
    zIndex: '1',
    [theme.breakpoints.up('xl')]: {
      marginBottom: 0,
      width: '30%',
    },
  },
  optionForm: {
    margin: '1rem 0',
    '& form': {
      display: 'flex',
      justifyContent: 'flex-end',
      flexDirection: 'column',
    },
    [theme.breakpoints.up('xl')]: {
      margin: 0,
      width: props => (props.isLargeContent ? '100%' : '70%'),
      '& form': {
        display: 'flex',
        flexDirection: 'row',
      },
    },
  },
  optionContainer: {
    [theme.breakpoints.up('xl')]: {
      width: props => (props.isLargeContent ? '100%' : '70%'),
    },
  },
  jumperListContainer: {
    marginTop: '-1rem',
  },
  dropDownItemSelectedBorder: {
    width: '140px',
    marginTop: '1px',
    borderBottom: `1px solid rgba(0, 0, 0, 0.42)`,
  },
  titleInput: {
    color: theme.palette.juridicTextColor,
    fontFamily: 'ItauText-Bold',
  },
  btnContractMarginLeft: {
    margin: '1rem',
    [theme.breakpoints.up('xl')]: {
      margin: '1.5rem 0 0 0',
    },
    '& button': {
      width: '100%',
    },
  },
  optionSelected: {
    display: 'flex',
    flexDirection: 'row',
    '& > span': {
      marginTop: '-4px',
      marginBottom: '4px',
      marginRight: '10px',
      marginLeft: '10px',
    },
  },
}));
