import config from 'config';
import { Grid, Box, Hidden } from '@material-ui/core';
import { Section, Title } from 'components/Structural';
import Carousel from 'components/Carousel';
import { LazyLoad } from 'components';
import { ExclusivesProductCard } from './components';
import { useStyles } from './ItauExclusivesSection.styles';

export const focusOnExclusivesSection = () => {
  setTimeout(() => document.querySelector('.itau-exclusives-section').scrollIntoView());
  setTimeout(() => document.querySelector('#itau-exclusives-section-title').focus(), 1000);
};

const ItauExclusivesSection = () => {
  const styles = useStyles();
  const ITAU_BASE_URL = 'https://www.itau.com.br';
  const { URL_BUCKET_BASE } = config;
  const ITAU_EXCLUSIVES_PATH = '/home/otimizadas/exclusivos_itau';

  const productsList = [
    {
      title: 'cartão protegido itaú',
      subtitle: 'ir para o site do banco <i class="icon-sair" aria-hidden="true" />',
      startIcon: 'itaufonts_cartao',
      image: `${URL_BUCKET_BASE}${ITAU_EXCLUSIVES_PATH}/cartao_protegido.png`,
      className: styles.cartaoProtegidoCard,
      urlSite: `${ITAU_BASE_URL}/seguros/cartao-protegido/`,
      deepLink: 'itauvarejo://cartao_protegido',
    },
    {
      title: 'capitalização itaú',
      subtitle: 'ir para o site do banco <i class="icon-sair" aria-hidden="true" />',
      startIcon: 'itaufonts_premio',
      image: `${URL_BUCKET_BASE}${ITAU_EXCLUSIVES_PATH}/capitalizacao.png`,
      urlSite: `${ITAU_BASE_URL}/capitalizacao/`,
      deepLink: 'itauvarejo://servicos_pic',
    },
    {
      title: 'seguro viagem itaú',
      subtitle: 'ir para o site do banco <i class="icon-sair" aria-hidden="true" />',
      startIcon: 'itaufonts_seguro_viagens',
      image: `${URL_BUCKET_BASE}${ITAU_EXCLUSIVES_PATH}/seguro_viagem.png`,
      urlSite: `${ITAU_BASE_URL}/seguros/viagem/`,
      deepLink: 'itauvarejo://servicos_seguros',
    },
    {
      title: 'seguro de vida itaú',
      subtitle: 'ir para o site do banco <i class="icon-sair" aria-hidden="true" />',
      startIcon: 'itaufonts_seguro_vida',
      image: `${URL_BUCKET_BASE}${ITAU_EXCLUSIVES_PATH}/seguro_vida.png`,
      urlSite: `${ITAU_BASE_URL}/seguros/vida/`,
      deepLink: 'itauvarejo://spa/seguros-corretora/atendimento/%23/contratacao/planos',
    },
    {
      title: 'abra sua conta',
      endIcon: 'itaufonts_seta_right',
      cardBackgroundColor: '#106EB0',
      colorFont: '#FFFFFF',
      isLink: true,
      urlSite: `${ITAU_BASE_URL}/contas/conta-corrente/`,
    },
  ];

  const firstElement = productsList[0];
  const otherElements = productsList.slice(1);

  const getElements = () =>
    otherElements.map(product => (
      <Grid item>
        <ExclusivesProductCard {...product} />
      </Grid>
    ));

  return (
    <Section className="itau-exclusives-section" backgroundColor="#FFFFFF">
      <Title
        id="itau-exclusives-section-title"
        tabIndex="-1"
        text="**exclusivos Itaú feitos para vc**"
        subtitle="veja abaixo oportunidades exclusivas para **correntistas do banco Itaú**"
      />
      <LazyLoad>
        <Box display="flex" flexDirection={{ xs: 'column', lg: 'row' }}>
          <Grid item lg={6} className={styles.cardWrapper}>
            <ExclusivesProductCard isFirst {...firstElement} />
          </Grid>
          <Hidden smDown>
            <Grid container lg={6} justify="space-around" alignContent="space-between" className={styles.cardWrapper}>
              {getElements()}
            </Grid>
          </Hidden>
          <Hidden smUp>
            <Grid container>
              <h3 className={styles.otherExclusives}>Outros produtos exclusivos para clientes itaú</h3>
              <Carousel getElements={getElements} />
            </Grid>
          </Hidden>
        </Box>
      </LazyLoad>
    </Section>
  );
};

export default ItauExclusivesSection;
