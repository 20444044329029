import makeStyles from '@material-ui/core/styles/makeStyles';

export const useStyles = makeStyles(({ toRem, palette }) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: props => (props.totalPagesNumber > 1 ? `-${props.parentMargin}px` : 0),
    width: props => (props.totalPagesNumber > 1 ? `calc(100% + (${props.parentMargin}px * 2))` : '100%'),
  },
  carousel: {
    display: 'flex',
    padding: 0,
    paddingBottom: '10px',
    clipPath: 'inset(0 0 8px 0)',
    maxWidth: '100%',
    overflowX: 'auto',
    flexWrap: 'nowrap',
    alignItems: 'center',

    scrollbarWidth: 'none',
    '&::-webkit-scrollbar': {
      height: 0,
      width: 0,
    },

    '& > *': {
      flexShrink: 0,
      marginLeft: props => `${props.CAROUSEL_ITEMS_SPACING}px`,
    },

    '& > *:first-child': {
      marginLeft: props => (props.totalPagesNumber > 1 ? `${props.CAROUSEL_ITEMS_SPACING}px` : 0),
    },
  },
  carouselItem: {
    listStyle: 'none',
  },
  dotsGroup: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > * + *': {
      marginLeft: '0.5rem',
    },
  },
  dotItem: {
    border: `${toRem(2)} solid #231D19`,
    borderRadius: '50%',
    width: '1rem',
    height: '1rem',
    cursor: 'pointer',
    flexShrink: 0,
    transition: 'all 200ms linear',
  },
  dotItemSelected: {
    backgroundColor: palette.primary.main,
    border: `${toRem(2)} solid ${palette.primary.main}`,
  },
  emptyItem: {
    width: props => `${props.ITEM_WIDTH}px`,
    height: props => `${props.ITEM_HEIGHT}px`,
  },
}));
