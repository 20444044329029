import React from 'react';
import { styled } from '@material-ui/core';
import MuiAddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import i18next from 'i18next';
import Hidden from '@material-ui/core/Hidden';
import { SubheaderWithDivider } from 'components/SubheaderWithDivider/SubheaderWithDivider';

const AddIcon = styled(MuiAddIcon)(({ theme }) => ({
  borderRadius: '100%',
  border: `3px solid${theme.palette.primary.main}`,
  color: theme.palette.primary.main,
  marginLeft: '1rem',
  width: '2.5rem',
  height: '2.5rem',
  [theme.breakpoints.up('md')]: {
    width: '1.5rem',
    height: '1.5rem',
    border: `1px solid${theme.palette.primary.main}`,
  },
}));

export function WelfareAdditionalAssistanceSubheader() {
  return (
    <>
      <Hidden mdDown>
        <SubheaderWithDivider>
          <Box display="flex" alignItems="center">
            <Box fontSize="1.5rem" m={0} fontFamily="ItauText-Regular" component="h2">
              {i18next.t('WELFARE.LANDING.ADDITIONAL_ASSISTANCE_SUBHEADER')}
            </Box>
            <AddIcon />
          </Box>
        </SubheaderWithDivider>
      </Hidden>
      <Hidden lgUp>
        <Box fontSize="1.5rem" m={0} fontFamily="ItauText-Regular" component="h2" textAlign="center">
          {i18next.t('WELFARE.LANDING.ADDITIONAL_ASSISTANCE_SUBHEADER')}
        </Box>
        <SubheaderWithDivider>
          <Box display="flex" alignItems="center">
            <AddIcon />
          </Box>
        </SubheaderWithDivider>
      </Hidden>
    </>
  );
}
