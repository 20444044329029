import React from 'react';
import { Hidden } from '@material-ui/core';
import { Section, Title } from 'components/Structural';
import Carousel from 'components/Carousel';
import config from 'config';

import LogoPorto from 'assets/images/home-logo-porto-seguro.png';
import LogoAzul from 'assets/images/home-logo-azul.png';
import LogoMetLife from 'assets/images/home-logo-metlife.png';

import { DesktopPartnerContainer, PartnerItem } from './components';

const { URL_BUCKET_BASE } = config;
const PARTNERS_PATH = '/parceiros/otimizadas';

const partners = [
  {
    image: LogoPorto,
    name: 'Porto Seguro',
    width: '150',
    height: '70',
  },
  {
    image: LogoAzul,
    name: 'Azul Seguros',
    width: '100',
    height: '60',
  },
];

export default function PartnersSection() {
  function sortByAlphabeticalOrder(partner1, partner2) {
    if (partner1.name < partner2.name) {
      return -1;
    }
    if (partner1.name > partner2.name) {
      return 1;
    }
    return 0;
  }

  function PartnersElementList() {
    return partners.sort(sortByAlphabeticalOrder).map((partner) => <PartnerItem {...partner} />);
  }

  return (
    <Section backgroundColor="#F5F5F5">
      <Title
        text="**conheça nossos parceiros**"
        subtitle="para te proporcionar um serviço de qualidade nós trabalhamos com as melhores empresas do mercado"
      />
      <Hidden lgUp>
        <Carousel getElements={PartnersElementList} />
      </Hidden>
      <Hidden mdDown>
        <DesktopPartnerContainer getElements={PartnersElementList} />
      </Hidden>
    </Section>
  );
}
