import React from 'react';
import i18n from 'i18next';
import AdditionalAssistance from 'components/AdditionalAssistance/AdditionalAssistance';
import { Loading } from 'components';
import { formatToBRLCurrency } from 'utils/currencyUtils';
import config from 'config';

const { URL_BUCKET_BASE } = config;

export function WelfareAdditionalAssistance({
  handleSelectCoverage,
  assistanceList,
  detailsList,
  handleExpandAdditionalAssistance,
  onClickGeneralConditions,
}) {
  if (!assistanceList) return <Loading />;

  const formattedAssistanceList = assistanceList
    .filter((item) => !item.assistencia_obrigatoria)
    .map((item, idx) => {
      const details = detailsList?.find((i) => i.id_assistencia === item.id_assistencia)?.descricao;

      return {
        id: `chkAdditionalAssists_screenHome_${idx}`,
        title: item.nome?.toLowerCase(),
        description: item.resumo,
        details: details || [],
        price: i18n.t('WELFARE.LANDING.ADDITIONAL_ASSISTANCE_CARD.PRICE_TEXT', {
          priceValue: formatToBRLCurrency(item.premio),
        }),
        onChange: () => handleSelectCoverage(item.id_assistencia, item.identificador),
        imageSrc: `${URL_BUCKET_BASE}/bem_estar/otimizadas/ilustracoes/${item.identificador}.png`,
      };
    });

  return (
    <AdditionalAssistance
      assistanceList={formattedAssistanceList}
      accordionExpandedText={i18n.t('WELFARE.LANDING.ADDITIONAL_ASSISTANCE_CARD.ACCORDION_EXPANDED_TEXT')}
      accordionClosedText={i18n.t('WELFARE.LANDING.ADDITIONAL_ASSISTANCE_CARD.ACCORDION_CLOSED_TEXT')}
      accordionButtonId="btnExpandAdditionalAssists_screenHome"
      onClickExpand={handleExpandAdditionalAssistance}
      generalConditionUrl={`${URL_BUCKET_BASE}/termos/bem-estar/cg_realiza_bem_estar.pdf`}
      generalConditionText={i18n.t('WELFARE.LANDING.MAIN_ASSISTANCE_CARD.GENERAL_CONDITIONS_TEXT')}
      onClickGeneralConditions={onClickGeneralConditions}
    />
  );
}
