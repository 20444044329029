/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useStyles } from './pageStyles';

const BannerSuccess = ({ title, icon }) => {
  const styles = useStyles();
  const focusRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      if (focusRef.current) focusRef.current.focus();
    }, 100);
  }, []);

  return (
    <Box className={styles.alert} data-testid="BannerSuccess">
      <Box className={`container ${styles.container}`}>
        {icon && <i aria-hidden className={`${icon} ${styles.icon}`} data-testid={`icon-${icon}`} />}
        <div ref={focusRef} className={styles.text} tabIndex={-1}>
          <span role="alert">{title}</span>
        </div>
      </Box>
    </Box>
  );
};

BannerSuccess.prototype = {
  title: PropTypes.any,
  icon: PropTypes.any,
};

export default BannerSuccess;
